import React from "react";
import PropTypes from "prop-types";

import { FaArrowDown } from "react-icons/fa/";
import AppleLogo from "!svg-react-loader!../../images/svg-icons/US_UK_Apple_Podcasts_Listen_Badge_RGB.svg?name=ApplePodcastsLogo";

const Hero = props => {
  const { scrollToContent, backgrounds, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <h1> Welcome to Reality Quest</h1>
        <div className="hero-badges">
          {/* Apple Podcasts Badge */}
          <a
            href="https://podcasts.apple.com/us/podcast/reality-quest/id1481828623"
            target="_blank"
            rel="noreferrer noopener"
          >
            {/* <div className="icon"> */}
            <AppleLogo height="35" />
            {/* </div> */}
          </a>
          {/* Soundcloud Badge */}
          {/* <iframe
            allowTransparency="true"
            scrolling="no"
            frameBorder="no"
            src="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Freality-quest-podcast&color=orange_white&size=48" 
            width="40"
            height="40"
          ></iframe> */}
          {/* Google Play Badge */}
          <a
            href="https://play.google.com/music/m/Ieuai4ihcxbxtuz7r4szimwejwm?t=Reality_Quest&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-mu-PartBadge-Mar2515-1"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              alt="Listen on Google Play Music"
              src="https://play.google.com/intl/en_us/badges-music/images/badges/en_badge_web_music.png"
              height="35"
            />
          </a>
        </div>
        <a href="/about" className="btn-platform-lists">
          Listen on other platforms
        </a>
        <button onClick={scrollToContent} aria-label="scroll">
          <FaArrowDown />
        </button>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          align-items: center;
          background: ${theme.hero.background} no-repeat center;
          background-image: url(${backgrounds.mobile});
          background-size: cover;
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          min-height: 100vh;
          height: 100px;
          padding: ${theme.space.inset.l};
          padding-top: ${theme.header.height.homepage};
        }

        h1 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          text-remove-gap: both 0 "Open Sans";

          :global(strong) {
            position: relative;

            &::after,
            &::before {
              content: "›";
              color: ${theme.text.color.attention};
              margin: 0 ${theme.space.xs} 0 0;
              text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
            }
            &::after {
              content: "‹";
              margin: 0 0 0 ${theme.space.xs};
            }
          }
        }

        .hero-badges {
          text-align: center;
        }

        .hero-badges:first-child {
          padding-right: 10px;
        }

        .btn-platform-lists {
          background: #000;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          height: 35px;
          border: 1px solid #999;
          border-radius: 6px;
          padding: 5px 10px;
          margin: 5px 0 30px 0;
          transition: transform 0.5s, background 0.5s, font-weight 0.5s;
        }

        .btn-platform-lists:hover {
          background: #ff0050;
          color: #fff;
          transform: scale(1.05, 1.05);
        }

        button {
          background: ${theme.background.color.brand};
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.m};
          padding: ${theme.space.m} ${theme.space.m};
          cursor: pointer;
          width: ${theme.space.xl};
          height: ${theme.space.xl};

          &:focus {
            outline-style: none;
            background: ${theme.color.brand.primary.active};
          }

          :global(svg) {
            position: relative;
            top: 5px;
            fill: ${theme.color.neutral.white};
            stroke-width: 40;
            stroke: ${theme.color.neutral.white};
            animation-duration: ${theme.time.duration.long};
            animation-name: buttonIconMove;
            animation-iteration-count: infinite;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.tablet});
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
